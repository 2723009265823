import apiClient from "utils/apiClient";

import {
  ADMIN_LIST_ALL_MEMBERS,
  CREATE_MEMBER,
  FILTER_BY_ARCHIVE_STATUS,
  GET_ERRORS,
  GET_MEMBER,
  LIST_ALL_MEMBERS,
  MEMBER_LOADING,
  UPDATE_MEMBER,
} from "./actionTypes";
import { addFlashMessages } from "./flashMessagesAction";

export const list_members = () => (dispatch) => {
  dispatch(setMemberLoading());
  apiClient()
    .get(`/api/members/all`)
    .then((response) => {
      dispatch({
        type: LIST_ALL_MEMBERS,
        payload: response.data.results,
      });
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setMemberLoading("false"));
    });
};

export const list_admin_members = () => (dispatch) => {
  dispatch(setMemberLoading());
  apiClient()
    .get(`/api/admin/members/all`)
    .then((response) => {
      dispatch({
        type: ADMIN_LIST_ALL_MEMBERS,
        payload: response.data.results,
      });
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setMemberLoading("false"));
    });
};

export const create_member = (data, history) => (dispatch) => {
  dispatch(setMemberLoading());
  apiClient()
    .post(`/api/members/create`, data)
    .then((response) => {
      dispatch({
        type: CREATE_MEMBER,
        payload: response.data,
      });
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "member_create",
          text: response.data.message,
        })
      );
      history.push("/users");
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setMemberLoading("false"));
    });
};
export const create_embed_member = (data, history) => (dispatch) => {
  dispatch(setMemberLoading());
  apiClient()
    .get("/sanctum/csrf-cookie")
    .then(() => {
      apiClient()
        .post(`/api/members/create/embed`, data)
        .then((response) => {
          console.log(response.data);
          dispatch(
            addFlashMessages({
              type: "success",
              eventFor: "member_create",
              text: response.data.message,
            })
          );
          dispatch(setMemberLoading("false"));
          history.push("/user/embedd/success");
        })
        .catch((err) => {
          typeof err.response !== "undefined" &&
            dispatch({ type: GET_ERRORS, payload: err.response.data });
          dispatch(setMemberLoading("false"));
        });
    });
};

export const update_member = (data, history) => (dispatch) => {
  dispatch(setMemberLoading());
  apiClient()
    .put(`/api/members/update`, data)
    .then((response) => {
      dispatch({
        type: UPDATE_MEMBER,
        payload: response.data,
      });
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "member_update",
          text: response.data.message,
        })
      );
      history.push("/users");
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setMemberLoading("false"));
    });
};

export const get_member_by_id = (id, history) => (dispatch) => {
  dispatch(setMemberLoading());
  apiClient()
    .get(`/api/members/${id}`)
    .then((response) => {
      dispatch({
        type: GET_MEMBER,
        payload: response.data,
      });
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });

      dispatch(
        addFlashMessages({
          type: "error",
          eventFor: "member_update",
          text: err.response.data.message,
        })
      );
      dispatch(setMemberLoading("false"));
      history.push("/users");
    });
};

export const archive_member = (data) => (dispatch) => {
  //dispatch(setMemberLoading());
  apiClient()
    .put(`/api/members/archive`, data)
    .then((response) => {
      dispatch({
        type: UPDATE_MEMBER,
        payload: response.data.result,
      });
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "member_update",
          text: response.data.message,
        })
      );
      //history.push("/users");
    })
    .catch((err) => {
      //dispatch(setMemberLoading("false"));
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const member_sync = (id) => (dispatch) => {
  dispatch(setMemberLoading());
  apiClient()
    .post(`/api/member/sync`, { id })
    .then((res) => {
      //console.log(res);
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "member_update",
          text: res.data.message,
        })
      );
      dispatch({
        type: UPDATE_MEMBER,
        payload: res.data.result,
      });
    })
    .catch((err) => {
      dispatch(setMemberLoading("false"));
      typeof err.response !== "undefined" &&
        //dispatch({ type: GET_ERRORS, payload: err.response.data });
        dispatch(
          addFlashMessages({
            type: "error",
            eventFor: "member_update",
            text: err.response.data.message,
          })
        );
    });
};

export const filter_member_by_archive_status = (filter) => {
  return {
    type: FILTER_BY_ARCHIVE_STATUS,
    filter,
  };
};

export const setMemberLoading = (show = "true") => {
  return {
    type: MEMBER_LOADING,
    payload: show,
  };
};
