import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { Button, Icon, Textarea } from "@material-tailwind/react";
import { Link, NavLink } from "react-router-dom";
import Loader from "components/Loader";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFlashMessages } from "actions/flashMessagesAction";
import { useEffect } from "react";
import FlashMessageList from "components/flash/FlashMessageList";
import { archive_member } from "actions/memberActions";
import { member_sync } from "actions/memberActions";
import { resetErrors } from "actions/flashMessagesAction";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import moment from "moment";

export default function MemberCardTable(props) {
  const { reseller } = useSelector((state) => state.auth);
  const { flash } = useSelector((state) => state.flash);
  const errorMsg = useSelector((state) => state.errors);
  const [flashMsg, setFlashMsg] = useState([]);
  const [tableView, setTableView] = useState("0");
  const [errorAlert, setErrorAlert] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const [copySuccess, setCopySuccess] = useState("");
  const [codeArea, setCodeArea] = useState("");

  const dispatch = useDispatch();

  const clearFlash = () => {
    setTimeout(() => {
      dispatch(clearFlashMessages());
      setFlashMsg([]);
    }, 10000);
  };
  useEffect(() => {
    dispatch(resetErrors());
    if (flash && flash.length > 0) {
      setFlashMsg(flash);
      clearFlash();
    }
    if (errorMsg.message) {
      setErrorAlert(errorMsg.message);
    }
  }, [flash, errorMsg.message, setErrorAlert]);

  function handleChange(e) {
    setTableView(e.target.value);
  }

  const archiveNow = ({ member, val }) => {
    dispatch(archive_member({ id: member, is_archived: val }));
  };

  const migrateMember = async (member) => {
    const confirmBox = window.confirm(
      "Migrating a user is an irreversible process and would lead to deduction of credits from your account. Are you sure to proceed with this action?"
    );
    if (confirmBox === true) {
      dispatch(member_sync(member));
    }
  };

  const { members, loading, name, link } = props;

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("success");
    } catch (err) {
      setCopySuccess("failed");
    }
  };

  function EmbeddUser(id) {
    const res_id = btoa(reseller.id);
    let form = `<iframe className="webform-signup" name="clpSignUpForm" id="SignUpClp${reseller.id}" src="https://clpadmin.com/user/request/${res_id}" style="background: white" frameborder="0"></iframe>`;
    setCodeArea(form);
    let content = (
      <div>
        <Textarea
          color="lightBlue"
          size="sm"
          //onChange={(e) => setCodeArea(e.target.value)}
          outline={true}
          placeholder="User Signup form"
          defaultValue={form}
        />
      </div>
    );
    console.log(form);
    setModalContent(content);
    setShowModal(true);
  }

  let setContent;
  if (loading) {
    setContent = (
      <tr>
        <td colSpan="6">
          <Loader />
        </td>
      </tr>
    );
  } else if (members.length === 0) {
    setContent = (
      <tr>
        <th
          colSpan="6"
          className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-center"
        >
          No records found.
        </th>
      </tr>
    );
  } else {
    setContent = members
      .filter((v) =>
        tableView !== "2"
          ? typeof v.is_archived !== "undefined" &&
            v.is_archived.toString() === tableView
          : v
      )
      .map((item) => (
        <tr key={item.id}>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.first_name && item.first_name}{" "}
            {item.first_name && item.last_name}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.email && item.email}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.plan_id === "personal" ? "Personal" : "Personal Plus"}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.phone}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {moment(item.created_at).format("MM/DD/YYYY hh:mm A")}
          </th>
          <th className="flex border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.is_synced === "0" ||
              (item.is_synced === 0 && (
                <NavLink
                  to={`/user/edit/${item.id}`}
                  className="mr-1 false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-full w-8 h-8 p-0 grid place-items-center text-xs leading-normal text-white bg-blue-500 hover:bg-blue-700 focus:bg-blue-400 active:bg-blue-800 shadow-md-blue hover:shadow-lg-blue"
                  title="Edit User"
                >
                  <Icon name="edit" size="sm" color="white" />
                </NavLink>
              ))}
            {item.is_archived.toString() === "0" ? (
              <Button
                color="red"
                buttonType="filled"
                size="sm"
                rounded={true}
                block={false}
                iconOnly={true}
                ripple="light"
                onClick={() => archiveNow({ member: item.id, val: "1" })}
                title="Archive User"
              >
                <Icon name="archive" size="sm" />
              </Button>
            ) : (
              <Button
                color="yellow"
                buttonType="filled"
                size="sm"
                rounded={true}
                block={false}
                iconOnly={true}
                ripple="light"
                onClick={() => archiveNow({ member: item.id, val: "0" })}
                title="Unarchive User"
              >
                <Icon name="unarchive" size="sm" />
              </Button>
            )}
            {item.is_synced === "1" || item.is_synced === 1 ? (
              <span
                style={{
                  color: "green",
                  fontStyle: "italic",
                  padding: "5px 0px 0px 5px",
                }}
              >
                User Migrated!
              </span>
            ) : (
              <Button
                color="purple"
                className="ml-1"
                buttonType="filled"
                size="sm"
                rounded={true}
                block={false}
                iconOnly={false}
                ripple="light"
                onClick={() => migrateMember(item.id)}
                title="Unarchive User"
              >
                <Icon name="upload" size="sm" /> Migrate
              </Button>
            )}
          </th>
        </tr>
      ));
  }

  return (
    <Card>
      <CardHeader
        color="orange"
        contentPosition="left"
        className={link && "flexIt"}
      >
        <h2 className="text-white text-2xl">{name}</h2>
        <div style={{ fontSize: "17px", fontStyle: "italic" }}>
          <input
            type="radio"
            name="view"
            value="2"
            style={{
              height: "18px",
              width: "18px",
              verticalAlign: "text-top",
              cursor: "pointer",
            }}
            checked={tableView === "2"}
            onChange={handleChange}
          />
          <span style={{ marginRight: "10px" }}>All</span>
          <input
            type="radio"
            name="view"
            value="1"
            style={{
              height: "18px",
              width: "18px",
              verticalAlign: "text-top",
              cursor: "pointer",
            }}
            checked={tableView === "1"}
            onChange={handleChange}
          />
          <span style={{ marginRight: "10px" }}>Archived</span>
          <input
            type="radio"
            name="view"
            value="0"
            style={{
              height: "18px",
              width: "18px",
              verticalAlign: "text-top",
              cursor: "pointer",
            }}
            checked={tableView === "0"}
            onChange={handleChange}
          />
          <span style={{ marginRight: "10px" }}>Unarchived</span>
        </div>
        <Button
          color="white"
          buttonType="outline"
          size="regular"
          rounded={false}
          block={false}
          iconOnly={false}
          onClick={(e) => EmbeddUser("5")}
          ripple="dark"
        >
          <Icon name="code_off" /> User Embedd Form
        </Button>

        {link && (
          <Link
            className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-2.5 px-6 text-xs leading-normal bg-transparent border border-solid shadow-none text-white-500 border-white-500 hover:bg-white-50 hover:border-white-700 hover:text-white-700 hover:bg-white-50 active:bg-white-100"
            to={link}
          >
            Add New
          </Link>
        )}
      </CardHeader>
      <CardBody>
        <div className="overflow-x-auto">
          {flashMsg && (
            <FlashMessageList
              messages={
                Array.isArray(flashMsg)
                  ? flashMsg.filter(
                      (f) =>
                        f.eventFor === "member_update" ||
                        f.eventFor === "member_create"
                    )
                  : {}
              }
            />
          )}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr key="head">
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Name
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Email
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Plan
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Phone
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Created At
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{setContent}</tbody>
          </table>
        </div>
      </CardBody>
      <Modal active={showModal} toggler={() => setShowModal(false)}>
        <ModalHeader toggler={() => setShowModal(false)}>
          Embed User Signup Form
        </ModalHeader>
        <ModalBody>
          <div className="text-base leading-relaxed text-gray-600 font-normal embedModal">
            {modalContent}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => copyToClipBoard(codeArea)}
            buttonType="outline"
          >
            Copy
          </Button>
          {copySuccess === "success" && (
            <span style={{ color: "green" }}>Copied!</span>
          )}
          <NavLink
            to={`/user/request/${btoa(reseller.id)}`}
            target="_blank"
            className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-2.5 px-6 text-xs leading-normal bg-transparent border border-solid shadow-none text-red-500 border-red-500 hover:bg-red-50 hover:border-red-700 hover:text-red-700 hover:bg-red-50 active:bg-red-100"
          >
            Preview
          </NavLink>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => setShowModal(false)}
            ripple="dark"
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
}
