import apiClient from "utils/apiClient";
import {
  ADMIN_CREATE_RESELLER,
  ADMIN_GET_RESELLER,
  ADMIN_LIST_ALL_RESELLERS,
  ADMIN_RESELLER_LOADING,
  ADMIN_UPDATE_RESELLER,
  GET_ERRORS,
} from "./actionTypes";
import { addFlashMessages } from "./flashMessagesAction";

export const list_resellers = () => (dispatch) => {
  dispatch(setResellerLoading());
  apiClient()
    .get(`/api/admin/resellers/all`)
    .then((response) => {
      dispatch({
        type: ADMIN_LIST_ALL_RESELLERS,
        payload: response.data.results,
      });
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setResellerLoading("false"));
    });
};
export const get_reseller_by_id = (id, history) => (dispatch) => {
  dispatch(setResellerLoading());
  apiClient()
    .get(`/api/admin/reseller/${id}`)
    .then((response) => {
      dispatch({
        type: ADMIN_GET_RESELLER,
        payload: response.data,
      });
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });

      dispatch(
        addFlashMessages({
          type: "error",
          eventFor: "reseller_update",
          text: err.response.data.message,
        })
      );
      dispatch(setResellerLoading("false"));
      history.push("/master/resellers");
    });
};
export const create_reseller = (data, history) => (dispatch) => {
  dispatch(setResellerLoading());
  apiClient()
    .post(`/api/admin/reseller/create`, data)
    .then((response) => {
      dispatch({
        type: ADMIN_CREATE_RESELLER,
        payload: response.data,
      });
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "reseller_create",
          text: response.data.message,
        })
      );
      history.push("/master/resellers");
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setResellerLoading("false"));
    });
};

export const update_reseller = (data, history) => (dispatch) => {
  dispatch(setResellerLoading());
  apiClient()
    .put(`/api/admin/reseller/update`, data)
    .then((response) => {
      console.log(response.data);
      dispatch({
        type: ADMIN_UPDATE_RESELLER,
        payload: response.data,
      });
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "reseller_update",
          text: response.data.message,
        })
      );
      history.push("/master/resellers");
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setResellerLoading("false"));
    });
};

export const archive_reseller = (data) => (dispatch) => {
  apiClient()
    .put(`/api/admin/reseller/archive`, data)
    .then((response) => {
      dispatch({
        type: ADMIN_UPDATE_RESELLER,
        payload: response.data.result,
      });
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "reseller_update",
          text: response.data.message,
        })
      );
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const reset_credits = (res_id) => (dispatch) => {
  apiClient()
    .put(`/api/admin/reseller/archive`, res_id)
    .then((response) => {
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "reseller_update",
          text: response.data.message,
        })
      );
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const setResellerLoading = (show = "true") => {
  return {
    type: ADMIN_RESELLER_LOADING,
    payload: show,
  };
};
