// Constant action dispatch types

export const ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE";
export const DELETE_FLASH_MESSAGE = "DELETE_FLASH_MESSAGE";

export const GET_ERRORS = "GET_ERRORS";
export const RESET_ERRORS = "RESET_ERRORS";

export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";
export const ADMIN_AUTH_LOADING = "ADMIN_AUTH_LOADING";

export const LOGIN_RESELLER = "LOGIN_RESELLER";
export const REGISTER_RESELLER = "REGISTER_RESELLER";
export const SET_CURRENT_RESELLER = "SET_CURRENT_RESELLER";
export const RESELLER_AUTH_LOADING = "RESELLER_AUTH_LOADING";

export const LIST_ALL_MEMBERS = "LIST_ALL_MEMBERS";
export const ADMIN_LIST_ALL_MEMBERS = "ADMIN_LIST_ALL_MEMBERS";
export const CREATE_MEMBER = "CREATE_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const GET_MEMBER = "GET_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const ADMIN_DELETE_MEMBER = "ADMIN_DELETE_MEMBER";
export const FILTER_BY_ARCHIVE_STATUS = "FILTER_BY_ARCHIVE_STATUS";
export const MEMBER_LOADING = "MEMBER_LOADING";

export const PAYMENT_LOADING = "PAYMENT_LOADING";
export const LIST_PAYMENTS = "LIST_PAYMENTS";
export const ADMIN_LIST_PAYMENTS = "ADMIN_LIST_PAYMENTS";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const FILTER_PAYMENTS_BY_ARCHIVE_STATUS =
  "FILTER_PAYMENTS_BY_ARCHIVE_STATUS";
export const WALLET_LOADING = "PAYMENT_LOADING";
export const GET_WALLET_AMOUNT = "GET_WALLET_AMOUNT";
export const ADD_WALLET_AMOUNT = "ADD_WALLET_AMOUNT";
export const MINUS_WALLET_AMOUNT = "MINUS_WALLET_AMOUNT";

export const ADMIN_LIST_ALL_RESELLERS = "ADMIN_LIST_ALL_RESELLERS";
export const ADMIN_CREATE_RESELLER = "ADMIN_CREATE_RESELLER";
export const ADMIN_GET_RESELLER = "ADMIN_GET_RESELLER";
export const ADMIN_UPDATE_RESELLER = "ADMIN_UPDATE_RESELLER";
export const ADMIN_DELETE_RESELLER = "ADMIN_DELETE_RESELLER";
export const ADMIN_RESELLER_LOADING = "ADMIN_RESELLER_LOADING";
