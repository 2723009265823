import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { create_member } from "actions/memberActions";
import { resetErrors } from "actions/flashMessagesAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { get_member_by_id } from "actions/memberActions";
import { update_member } from "actions/memberActions";

const CreateEditMemberForm = () => {
  const { id } = useParams();
  let history = useHistory();

  const { loading, member, members } = useSelector((state) => state.members);
  const errorsSubmission = useSelector((state) => state.errors);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [planId, setPlanId] = useState("personal");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const get_member = () => dispatch(get_member_by_id(id, history));

  useEffect(() => {
    if (errorsSubmission) {
      setErrors(errorsSubmission);
    }
    if (id) {
      get_member();
    }
    const chkMem = members.filter((item) => item.id == id);
    if (chkMem[0]) {
      setFirstName(chkMem[0].first_name ? chkMem[0].first_name : "");
      setLastName(chkMem[0].last_name ? chkMem[0].last_name : "");
      setEmail(chkMem[0].email ? chkMem[0].email : "");
      setUsername(chkMem[0].username ? chkMem[0].username : "");
      setPassword(chkMem[0].password ? chkMem[0].password : "");
      setPhone(chkMem[0].phone ? chkMem[0].phone : "");
      setAddress(chkMem[0].address ? chkMem[0].address : "");
      setCity(chkMem[0].city ? chkMem[0].city : "");
      setState(chkMem[0].state ? chkMem[0].state : "");
      setZip(chkMem[0].zip ? chkMem[0].zip : "");
      setPlanId(chkMem[0].plan_id ? chkMem[0].plan_id : "");
    } else if (id && member.length > 0) {
      setFirstName(member[0].first_name ? member[0].first_name : "");
      setLastName(member[0].last_name ? member[0].last_name : "");
      setEmail(member[0].email ? member[0].email : "");
      setUsername(member[0].username ? member[0].username : "");
      setPassword(member[0].password ? member[0].password : "");
      setPhone(member[0].phone ? member[0].phone : "");
      setAddress(member[0].address ? member[0].address : "");
      setCity(member[0].city ? member[0].city : "");
      setState(member[0].state ? member[0].state : "");
      setZip(member[0].zip ? member[0].zip : "");
      setPlanId(member[0].plan_id ? member[0].plan_id : "");
    }
  }, [errorsSubmission, id, dispatch, member.length]);

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  let loader;
  if (loading) {
    loader = <Loader />;
  }

  const saveForm = async (e) => {
    e.preventDefault();
    const data = {
      id: id ? id : "",
      firstName,
      lastName,
      email,
      username,
      password,
      phone,
      address,
      city,
      state,
      zip,
      planId,
    };
    //console.log(data);
    !id
      ? dispatch(create_member(data, history))
      : dispatch(update_member(data, history));
  };

  return (
    <Card>
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">
            {!id ? "Create User" : "Edit User"}
          </h2>
          <Link
            to="/users"
            style={{
              padding: "5px 15px",
              border: "1px solid",
              borderRadius: "5px",
            }}
          >
            All Users
          </Link>
        </div>
      </CardHeader>
      <CardBody>
        <form onSubmit={saveForm}>
          <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
            User Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="First Name"
                value={firstName && firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.firstName !== "undefined"
                    ? errors.errors.firstName[0]
                    : ""
                }
              />
            </div>
            <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Last Name"
                value={lastName && lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-6/12 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Username"
                value={username && username}
                onChange={(e) => setUsername(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.username !== "undefined"
                    ? errors.errors.username[0]
                    : ""
                }
              />
              <p className="pt-2" style={{ color: "red", fontSize: "13px" }}>
                Useful in case you decide to migrate this user to clpapp.
              </p>
            </div>
            <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
              <Input
                color="purple"
                placeholder="Password"
                type="password"
                value={password && password}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.password !== "undefined"
                    ? errors.errors.password[0]
                    : ""
                }
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="pt-2" style={{ color: "red", fontSize: "13px" }}>
                Useful in case you decide to migrate this user to clpapp.
              </p>
            </div>
            <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
              <Input
                type="email"
                color="purple"
                placeholder="Email Address"
                value={email && email}
                onChange={(e) => setEmail(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.email !== "undefined"
                    ? errors.errors.email[0]
                    : ""
                }
              />
            </div>
          </div>

          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Contact Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-12/12 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Address"
                value={address && address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-3/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Phone"
                value={phone && phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-3/12 px-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="City"
                value={city && city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="State"
                value={state && state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Zip"
                value={zip && zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </div>
          </div>
          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Plan Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 mb-10 font-light">
              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={planId === "personal"}
                    value="personal"
                    onChange={(e) => setPlanId(e.target.value)}
                  />
                  <input type="hidden" name="personal_price" value="99" />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inlineRadio10"
                  >
                    Personal
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="personal_plus"
                    checked={planId === "personal_plus"}
                    onChange={(e) => setPlanId(e.target.value)}
                  />
                  <input type="hidden" name="personal_plus_price" value="197" />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inlineRadio20"
                  >
                    Personal Plus
                  </label>
                </div>
              </div>
            </div>
          </div>

          <Button
            color="purple"
            buttonType="filled"
            size="regular"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
          >
            Submit
          </Button>
          {loader && loader}
        </form>
      </CardBody>
    </Card>
  );
};

export default CreateEditMemberForm;
