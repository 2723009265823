import apiClient from "utils/apiClient";
import Cookies from "js-cookie";

import { addFlashMessages } from "./flashMessagesAction";
import {
  ADMIN_AUTH_LOADING,
  GET_ERRORS,
  SET_CURRENT_ADMIN,
} from "./actionTypes";

export const login = (data, history) => (dispatch) => {
  dispatch(setAuthLoading());

  apiClient()
    .get("/sanctum/csrf-cookie")
    .then(() => {
      apiClient()
        .post(`/admin/login`, data)
        .then(function (response) {
          console.log(response);
          if (response.status === 200 || response.status === 204) {
            onLogin();
            dispatch(setCurrentAdmin(response.data.admin));
            history.push("/master/dashboard");
          }
        })
        .catch((err) => {
          typeof err.response !== "undefined" &&
            dispatch({ type: GET_ERRORS, payload: err.response.data });
          dispatch(setAuthLoading("false"));
        });
    });
};

export const update_admin = (data) => (dispatch) => {
  dispatch(setAuthLoading());
  apiClient()
    .put("api/admin/update", data)
    .then((response) => {
      dispatch(setCurrentAdmin(response.data));
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "admin_account_setting_update",
          text: response.data.message,
        })
      );
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setAuthLoading("false"));
    });
};

export const logout = () => (dispatch) => {
  apiClient()
    .post("/admin/logout")
    .then(function (response) {
      if (response.status === 204) {
        Cookies.remove("clpadmin_master_logged_in");
        dispatch(setCurrentAdmin({}));
      }
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const setCurrentAdmin = (reseller) => {
  return {
    type: SET_CURRENT_ADMIN,
    payload: reseller,
  };
};

const setAuthLoading = (show = "true") => {
  return {
    type: ADMIN_AUTH_LOADING,
    payload: show,
  };
};

const onLogin = () => {
  const insixtyMinutes = new Date(new Date().getTime() + 120 * 60 * 1000);
  console.log(insixtyMinutes);
  Cookies.set("clpadmin_master_logged_in", true, { expires: insixtyMinutes });
};

export const checkAdminAuthStatus = () => (dispatch) => {
  dispatch(setAuthLoading());
  apiClient()
    .get("/api/admin")
    .then((res) => {
      dispatch(setCurrentAdmin(res.data));
    })
    .catch((err) => {
      Cookies.remove("clpadmin_master_logged_in");
      dispatch(setCurrentAdmin({}));
    });
};
